@media only screen and (min-width: 768px) {
}
@media only screen and (max-width: 768px) {
  .google-calender-btn button {
    font-size: 12px;
  }

  .google-calender-btn {
    margin-bottom: 30px;
  }
}
.top-emoji {
  margin-bottom: 10px;
  text-align: center;
}
.btn-col button {
  min-width: 200px;
}
.event-container {
  width: 50%;
  margin: auto;
}

.event-bg-img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

@media only screen and (max-width: 768px) {
  .event-container {
    width: 100%;
    padding: 20px;
  }
}
